import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
export const takingClassState = atom<{
    value: string[][];
    indexs: number[];
    count: number;
}>({
    key: 'takingClass',
    default: {
        value: [],
        indexs: [],
        count: 0,
    },
    effects_UNSTABLE: [persistAtom],
    dangerouslyAllowMutability: true,
});
