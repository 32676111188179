import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useRecoilState, useRecoilValue } from 'recoil';
import { takingClassState } from '../atoms/takingClassState';
import { userState } from '../atoms/userState';
import { HomeTakingClassComponent } from '../components/Home/HomeTakingClassComponent';
import styles from '../styles/Home.module.scss';
const Layout = dynamic(() => import('../components/Layout'), {
    ssr: false,
});
const AdminComponent = dynamic(
    () => import('../components/Home/AdminComponent'),
);
const NotAdminComponent = dynamic(
    () => import('../components/Home/NotAdminComponent'),
);
const Home: NextPage = () => {
    const user = useRecoilValue(userState);
    const [takingClass, setTakingClass] =
        useRecoilState(takingClassState);
    return (
        <Layout title="単位確認" mainStyle={styles.main}>
            <h1
                className="text-4xl"
                style={{
                    background:
                        'linear-gradient(transparent 70%, #a7d6ff 70%)',
                }}>
                単位確認
            </h1>
            <HomeTakingClassComponent
                graduationCount={74 - takingClass.count}
                activeTime={user.activeTime}
            />
            {user.admin ? (
                <AdminComponent setTakingClass={setTakingClass} />
            ) : (
                <NotAdminComponent
                    user={user}
                    takingClass={takingClass}
                    setTakingClass={setTakingClass}
                />
            )}
        </Layout>
    );
};
export default Home;
