import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
export const userState = atom<{
    id: string;
    uid: string;
    displayName: string;
    email: string;
    admin: boolean;
    login: boolean;
    activeTime: string;
}>({
    key: 'user',
    default: {
        id: '',
        uid: '',
        displayName: '',
        email: '',
        admin: false,
        login: false,
        activeTime: '',
    },
    effects_UNSTABLE: [persistAtom],
    dangerouslyAllowMutability: true,
});
