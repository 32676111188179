import dynamic from 'next/dynamic';

const HomeSlectComponent = dynamic(
    () => import('./SelectComponent'),
    {
        ssr: false,
    },
);
export const HomeTakingClassComponent = ({
    graduationCount,
    activeTime,
}: {
    graduationCount: number;
    activeTime: string;
}) => {
    return (
        <div>
            <div className="text-center flex items-center justify-center flex-col h-full">
                <h2>履修した科目を選択してください</h2>
                <p suppressHydrationWarning>
                    {graduationCount <= 0
                        ? '卒業単位に達しています'
                        : `卒業まであと${graduationCount}単位`}
                </p>
                <p suppressHydrationWarning>
                    {Number(activeTime) >= 30
                        ? '卒業に必要な特別活動30時間を満たしています'
                        : `卒業に必要な特別活動あと${
                              30 - Number(activeTime)
                          }時間`}
                </p>
                <div className="my-2">
                    <HomeSlectComponent />
                </div>
            </div>
        </div>
    );
};
